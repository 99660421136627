import carSweepstakesApi from '../../api/car-sweepstakes-api';
const CAR_SWEEPSTAKES_CHECK_REQUEST = 'carSweepStakesCheckRequest';
const CAR_SWEEPSTAKES_CHECK_SUCCESS = 'carSweepStakesCheckSuccess';
const CAR_SWEEPSTAKES_RESET = 'carSweepStakesReset';
const CAR_SWEEPSTAKES_TECHNICAL_FAILURE = 'carSweepStakesTechnicalFailure';
const CAR_SWEEPSTAKES_SHOW_POPUP = 'carSweepStakesShowPopup';
const CAR_SWEEPSTAKES_HIDE_POPUP = 'carSweepStakesHidePopup';
const CAR_SWEEPSTAKES_SHOW_FORM = 'carSweepStakesShowForm';
const CAR_SWEEPSTAKES_HIDE_FORM = 'carSweepStakesHideForm';
const CAR_SWEEPSTAKES_SET_HAS_WON_CAN_TAKE = 'carSweepStakesSetHasWonCanTake';
const CAR_SWEEPSTAKES_SET_OVER_THIRTY_DAYS = 'carSweepStakesSetOverThirtyDays';
const CAR_SWEEPSTAKES_SET_HAS_WON_NOT_TAKEN = 'carSweepStakesSetHasWonNotTaken';

const carSweepstakesState = {
	loading: false,
	technicalError: false,
	popupVisible: false,
	formVisible: true,
	results: null,
	hasWonCanTake: null,
	overThirtyDays: null,
	hasWonNotTaken: null
};

const carSweepstakesActions = {
	check({ commit }, number) {
		commit(CAR_SWEEPSTAKES_CHECK_REQUEST);
		carSweepstakesApi
			.check(number)
			.then((response) => {
				commit(CAR_SWEEPSTAKES_CHECK_SUCCESS, response.data);
				commit(CAR_SWEEPSTAKES_HIDE_FORM);
			})
			.catch(() => {
				commit(CAR_SWEEPSTAKES_TECHNICAL_FAILURE);
			});
	},
	setResults({ commit }, data) {
		commit(CAR_SWEEPSTAKES_CHECK_SUCCESS, data);
	},
	hideForm({ commit }) {
		commit(CAR_SWEEPSTAKES_HIDE_FORM);
	},
	showPopup({ commit }, data) {
		commit(CAR_SWEEPSTAKES_SHOW_POPUP);
		commit(CAR_SWEEPSTAKES_SET_HAS_WON_CAN_TAKE, data.hasWonCanTake);
		commit(CAR_SWEEPSTAKES_SET_OVER_THIRTY_DAYS, data.overThirtyDays);
		commit(CAR_SWEEPSTAKES_SET_HAS_WON_NOT_TAKEN, data.hasWonNotTaken);
	},
	hidePopup({ commit }) {
		commit(CAR_SWEEPSTAKES_HIDE_POPUP);
	},
	reset({ commit }) {
		commit(CAR_SWEEPSTAKES_RESET);
		commit(CAR_SWEEPSTAKES_SHOW_FORM);
	}
};

const carSweepstakesMutations = {
	[CAR_SWEEPSTAKES_TECHNICAL_FAILURE](state) {
		state.technicalError = true;
		state.loading = false;
	},
	[CAR_SWEEPSTAKES_CHECK_SUCCESS](state, results) {
		state.results = results;
		state.loading = false;
	},
	[CAR_SWEEPSTAKES_CHECK_REQUEST](state) {
		state.loading = true;
	},
	[CAR_SWEEPSTAKES_SHOW_POPUP](state) {
		state.popupVisible = true;
	},
	[CAR_SWEEPSTAKES_SET_HAS_WON_CAN_TAKE](state, hasWonCanTake) {
		state.hasWonCanTake = hasWonCanTake;
	},
	[CAR_SWEEPSTAKES_SET_OVER_THIRTY_DAYS](state, overThirtyDays) {
		state.overThirtyDays = overThirtyDays;
	},
	[CAR_SWEEPSTAKES_SET_HAS_WON_NOT_TAKEN](state, hasWonNotTaken) {
		state.hasWonNotTaken = hasWonNotTaken;
	},
	[CAR_SWEEPSTAKES_HIDE_POPUP](state) {
		state.popupVisible = false;
	},
	[CAR_SWEEPSTAKES_RESET](state) {
		state.results = null;
	},
	[CAR_SWEEPSTAKES_HIDE_FORM](state) {
		state.formVisible = false;
	},
	[CAR_SWEEPSTAKES_SHOW_FORM](state) {
		state.formVisible = true;
	}
};

export default {
	namespaced: true,
	state: carSweepstakesState,
	mutations: carSweepstakesMutations,
	actions: carSweepstakesActions
};
